import { Injectable, Inject } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private lastToken;
    setLastToken(lastToken: string) {
        this.lastToken = lastToken
    }
    getLastToken(): string | null {
        return this.lastToken;
    }
}